<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="getCompanyAssignments"
      :class="{ 'border mb-50': windowWidth > 767, 'mb-0': windowWidth < 767 }"
      :items="assignments"
      :fields="fields"
      :total-rows="total"
      searchable
    >
      <template #physician="data">
        <app-user-info v-if="data.item.physician" :title="data.item.physician.auth.fullname" :alt_title="$physicianRole(data.item.roleKey)" icon="" variant="secondary" />
        <span v-else> - </span>
      </template>
      <template #isAssignmentActive="{ item }">
        <b-badge :variant="item.isAssignmentActive ? 'success' : 'danger'">
          {{ item.isAssignmentActive ? "Aktif" : "Pasif" }}
        </b-badge>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      assignments: [],
      filters: {},
      total: 0,
    };
  },
  computed: {
    fields() {
      return this.windowWidth < 767
        ? [
            { key: "physician", label: "Görevli Personel", sortable: true },
            { key: "isAssignmentActive", label: "Atama Durumu", sortable: true },
          ]
        : [
            { key: "physician", label: "Görevli Personel", sortable: true },
            ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil", sortable: true }]),
            { key: "isAssignmentActive", label: "Atama Durumu", sortable: true },
            {
              key: "createdAt",
              label: "Atama Tarihi",
              sortable: true,
              formatDateTime: true,
            },
            {
              key: "passivizationDate",
              label: "Kaldırılma Tarihi",
              sortable: true,
              formatDateTime: true,
            },
          ];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  watch: {
    selectedCompany() {
      this.getCompanyAssignments();
    },
    selectedBranch() {
      this.getCompanyAssignments();
    },
  },
  methods: {
    getCompanyAssignments() {
      if (this.selectedCompany) {
        this.$axios
          .get("/rest-assignments/paginated-assignments", {
            params: {
              ...this.filters,
              company: this.selectedCompany?._id,
              branch: this.selectedBranch,
              sortBy: this.filters?.sortBy ?? "isAssignmentActive",
              populates: JSON.stringify(["physician", "branch"]),
            },
            loading: "table",
          })
          .then(({ data: { data, total } }) => {
            this.assignments = data;
            this.total = total;
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
        /*   graphqlRequest(
          "/assignments",
          {
            query: `
          query assignments($company: String!, $branch: String){
            assignments(company:$company, branch: $branch){
            physician {fullname role} branch{name} isAssignmentActive createdAt passivizationDate
            }
        }
        `,
            variables: {
              company: this.selectedCompany?._id,
              branch: this.selectedBranch ? this.selectedBranch : undefined,
            },
          },
          { loading: "table" }
        )
          .then(({ assignments }) => {
            this.assignments = assignments.map((assignment) => ({
              ...assignment,
              _rowVariant: assignment.isAssignmentActive ? "" : "table-secondary",
            }));
          })
          .catch((err) => {
            console.log(err);
          }); */
      }
    },
  },
};
</script>

<style></style>
